import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RolePermissionService } from '@app/shared/services/role-permission/role-permission.service';
import { API_NAME } from '@app/shared/utils/api-util';
import { IUserProfile } from '@layout/models/layout-model';
import { finalize } from 'rxjs';
declare const gigya: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  rolePermissionService = inject(RolePermissionService);

  constructor(private readonly http: HttpClient) { }

  getProfileDetails() {
    const profile = localStorage?.getItem('profile') as string;

    try {
      const profileDetails = JSON?.parse(profile);
      return profileDetails as IUserProfile;
    } catch (error) {
      return this.rolePermissionService.getUserProfile();
    }
  }

  setAuthorize(params: any) {
    localStorage.setItem('isAuthorized', params);
  }

  getAuthorize() {
    const authorize =
      (localStorage.getItem('isAuthorized') as string) || 'false';
    try {
      const isAuthorized = JSON?.parse(authorize);
      return isAuthorized as boolean;
    } catch (error) {
      return false as boolean;
    }
  }

  isLoggedIn() {
    const loggedIn = (localStorage.getItem('isLoggedIn') as string) || 'false';
    try {
      const isLoggedIn = JSON?.parse(loggedIn);
      return isLoggedIn as boolean;
    } catch (error) {
      return false as boolean;
    }
  }

  redirectToURL(path: string) {
    window.location.href = window.location.origin + `/${path}`;
  }

  getToken(code: any) {
    return this.http.post(`${API_NAME.get_token}`, {
      code,
      withCredentials: true,
    });
  }

  userLogout() {
    return this.http.post(`${API_NAME.user_logout}`, {});
  }

  saveTokens(tokendata: any) {
    console.log(tokendata?.installed_product_serial_numbers);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem(
      'profile',
      JSON.stringify({
        email: tokendata?.User_Federation_ID,
        firstName: tokendata?.First_Name,
        lastName: tokendata?.Last_Name,
      })
    );

    localStorage.setItem('country', tokendata.Country);
    this.redirectToURL('');
  }

  getCountry() {
    try {
      return localStorage.getItem('country');
    } catch (error) {
      return '';
    }
  }

  logOut() {
    gigya.accounts.logout({
      callback: (response: any) => {
        if (response.errorCode === 0) {
          this.userLogout()
            .pipe(
              finalize(() => {
                this.clearStorage();
              })
            )
            .subscribe();
        }
      },
    });
  }

  clearStorage() {
    localStorage.clear();
    if (document.cookie.includes('_gig_llp')) {
      document.cookie = `_gig_llp=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Lax; domain=.${location?.host}; SameSite=None; Secure;`;
    }
    this.redirectToURL('');
  }
}
