import { IApplicationAreaList, IMenuLink } from '@layout/models/layout-model';
import { USER_ROLE } from './common-content-util';

export const HELP_TOUR_EVENT_NAME = 'helptourevent';
export const REPORET_CT = 'translation.SelectionPanel.report_ct';
const ACTIVE_IMAGE_PATH = '../../../../assets/images/SummaryY.svg';
const SUMMARY_W_IMAGE_PATH = '../../../../assets/images/SummaryW.svg';
export const SELECTEDREPORT_CTSP = 'CT';
export const SELECTEDREPORT_CTMR = 'CT-MP';
export const SELECTEDREPORT_MRSP = 'MR';
export const SELECTEDREPORT_MRMP = 'MR-MP';
export const EXTRACT_DOMAIN_NAME = [
  'www.insights-eu-preprod.numantra.us',
  'eu.insights.bayer.com',
  'www.insights-eu-dev.numantra.us',
  'www.insights-eu-uat.numantra.us',
  'eu.test.insights.bayer.com',
  'www.insights-eu.numantra.us',
];
export const USA_CANADA_COUNTRY = ['usa', 'canada'];
export const footerRoutes = {
  tools_privacy: '/resources/privacy',
  tools_contactus: '/resources/contactus',
};

export const MAIN_MENU = {
  cdm_metrics: 'cdm-metrics',
  report: 'report',
  resources: 'resources',
  user: 'admin',
};

export const PRODUCT_LINES = [
  'Centargo',
  'Stellant Dual',
  'Stellant Flex',
  'Stellant Single',
  'Stellant WorkStation',
  'Xperion',
];
export const OTHER_APPLICATION_AREAS: IApplicationAreaList[] = [
  {
    id: SELECTEDREPORT_CTSP,
    label: REPORET_CT,
  },
  {
    id: SELECTEDREPORT_CTMR,
    label: 'translation.SelectionPanel.report_ctmp',
  },
  {
    id: SELECTEDREPORT_MRSP,
    label: 'translation.SelectionPanel.report_mrsp',
  },
  {
    id: SELECTEDREPORT_MRMP,
    label: 'translation.SelectionPanel.report_mrmp',
  },
];

export const MODALITY_LIST_WITHOUT_TRANSLATION: IApplicationAreaList[] = [
  {
    id: SELECTEDREPORT_CTSP,
    label: '(CT-SP) Computed Tomography Single Patient',
  },
  {
    id: SELECTEDREPORT_CTMR,
    label: '(CT-MP) Computed Tomography Multi Patient',
  },
  {
    id: SELECTEDREPORT_MRSP,
    label: '(MR-SP) Magnetic Resonance Single Patient',
  },
  {
    id: SELECTEDREPORT_MRMP,
    label: '(MR-MP) Magnetic Resonance Multi Patient',
  },
];

export const CONTRASTDASHBOARDMENU: IMenuLink[] = [
  {
    name: 'translation.ContrastDashboard.header',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: '/cdm-metrics/my-dashboard',
  },
  {
    name: 'translation.ContrastMetrics.RepeatInjection.Title',
    imagePath: '../../../../assets/images/RepeatInjectionW.svg',
    activeImagePath: '../../../../assets/images/RepeatInjectionY.svg',
    url: '/cdm-metrics/repeat-injections',
    tourAnchor: 'repeatinjections',
  },
  {
    name: 'translation.ContrastMetrics.IVIssue.Title',
    imagePath: '../../../../assets/images/IVIssuesW.svg',
    activeImagePath: '../../../../assets/images/IVIssuesY.svg',
    url: 'cdm-metrics/iv-issues',
    tourAnchor: 'ivissues',
  },
  {
    name: 'translation.ContrastMetrics.LostTime.Title',
    imagePath: '../../../../assets/images/LostTimeW.svg',
    activeImagePath: '../../../../assets/images/LostTimeY.svg',
    url: 'cdm-metrics/lost-time',
    tourAnchor: 'losttime',
  },
  {
    name: 'translation.ContrastMetrics.ATypicalInjection.Title',
    imagePath: '../../../../assets/images/AtypicalTechnologistW.svg',
    activeImagePath: '../../../../assets/images/AtypicalTechnologistY.svg',
    url: 'cdm-metrics/tech-abort',
    tourAnchor: 'techabort',
  },
  {
    name: 'translation.ContrastMetrics.FlowRateLimited.Title',
    imagePath: '../../../../assets/images/AtypicalFlowrateW.svg',
    activeImagePath: '../../../../assets/images/AtypicalFlowrateY.svg',
    url: 'cdm-metrics/flow-rate-limited',
    tourAnchor: 'flowratelimit',
  },
  {
    name: 'translation.ContrastMetrics.SalineTestInject.Title',
    imagePath: '../../../../assets/images/SalineTestInjectW.svg',
    activeImagePath: '../../../../assets/images/SalineTestInjectY.svg',
    url: 'cdm-metrics/saline-test-inject',
    tourAnchor: 'salinetestinject',
  },
  {
    name: 'translation.ContrastMetrics.ContrastWaste.Title',
    imagePath: '../../../../assets/images/ContrastwasteW.svg',
    activeImagePath: '../../../../assets/images/ContrastwasteY.svg',
    url: 'cdm-metrics/contrast-waste',
    tourAnchor: 'unusedcontrast',
  },
  {
    name: 'translation.ContrastMetrics.POCDataQuality.Title',
    imagePath: '../../../../assets/images/DataCompletenessW.svg',
    activeImagePath: '../../../../assets/images/DataCompletenessY.svg',
    url: 'cdm-metrics/point-of-care-data-quality',
    tourAnchor: 'datacompleteness',
  },
];

export const REPORTMENU: IMenuLink[] = [
  {
    name: 'translation.ReportSideNav.standardReport',
    imagePath: SUMMARY_W_IMAGE_PATH,
    activeImagePath: ACTIVE_IMAGE_PATH,
    url: 'report/standardreport',
    tourAnchor: '',
    className: 'reports-menu',
  },
  {
    name: 'translation.ReportSideNav.customizedReport',
    imagePath: SUMMARY_W_IMAGE_PATH,
    activeImagePath: ACTIVE_IMAGE_PATH,
    url: 'report/customizedreport',
    tourAnchor: '',
    className: 'reports-menu',
  },
];
export const USERMENU: IMenuLink[] = [
  {
    name: 'User Management',
    imagePath: '../../../../assets/images/add-user.png',
    activeImagePath: '../../../../assets/images/add-user.png',
    url: 'admin/user',
    permissionName: [
      USER_ROLE.customer_external_admin,
      USER_ROLE.regional_admin,
      USER_ROLE.super_admin,
    ],
  },
  {
    name: 'Create a New User',
    imagePath: '../../../../assets/images/add-user.png',
    activeImagePath: '../../../../assets/images/add-user.png',
    url: 'admin/user/add-user',
    className: 'sub-menu',
    permissionName: [
      USER_ROLE.customer_external_admin,
      USER_ROLE.regional_admin,
      USER_ROLE.super_admin,
    ],
  },
  {
    name: 'Account Management',
    imagePath: '../../../../assets/images/add-user.png',
    activeImagePath: '../../../../assets/images/add-user.png',
    url: 'admin/account',
    permissionName: [USER_ROLE.regional_admin, USER_ROLE.super_admin],
  },
];
export const CONTRASTTITLE: Map<string, string> = new Map([
  ['LostTimeDataType', 'Lost Time,/'],
  ['PocDataQualityDataType', 'Data Completeness,/'],
  ['ContrastWasteDataType', 'Unused Contrast,/'],
  ['RepeatInjectionDataType', 'Repeat Injections,/'],
  ['AtypicalTechAbortDataType', 'Technologist Abort,/'],
  ['IVInjectionDataType', 'Abort With Repeat,/'],
  ['AtypicalFlowRateLimitedDataType', 'Flow Rate Limiting,/'],
  ['SalineTestInject', 'Saline Test Inject,/'],
]);

export const SERVICE_APPLICATION_AREA = 'service';
export const OTHER_APPLICATION_AREA = 'other';

export const BLACK_COLOR = '#1e272a';
export const YELLOW_COLOR = '#e3ca41';
export const YELLOW_COLOR_ALPHA = '#f0d41540';
export const WHITE_COLOR = '#ffffff';
export const LIGHT_GRAY_COLOR = '#B3B6B7';

export const DARK_GRAY_COLOR = '#90969f';
export const LIGHT_PURPLE_ALPHA = '#9f7ad126';
export const LIGHT_PURPLE_COLOR = '#9f7ad1';
export const DOUGHNUT_GRAY_COLOR = '#B2B6B7';
export const DANGER_COLOR = '#ec2227';
export const SUCCESS_COLOR = '#6bc200';
export const WARNING_COLOR = '#ffa000';
export const SESSION_PROFILE = 'profile';
export const START_COUNT = 0;
export const END_COUNT = 10;
export const HOSPITAL_DISPLAY_COUNT = 18;
export const DEFAULT_LANGUAGE = 'en-US';
export const DEFAULT_REGION = 'PST';
export const ROUTES_NAME = {
  cdmMetrics: '/cdm-metrics',
  metrics_repeatInjections: '/cdm-metrics/repeat-injections',
  metrics_ivissues: '/cdm-metrics/iv-issues',
  metrics_atypicalInjection: '/cdm-metrics/tech-abort',
  metrics_flowratelimited: '/cdm-metrics/flow-rate-limited',
  metrics_lostTime: '/cdm-metrics/lost-time',
  metrics_contrastWaste: '/cdm-metrics/contrast-waste',
  metrics_pointOfCareDataQuality: '/cdm-metrics/point-of-care-data-quality',
  metrics_salineTestInject: '/cdm-metrics/saline-test-inject',
  metrics_benchmark: '/cdm-metrics/benchmark_accessroute',
  protocol_permission: '/cdm-metrics/protocol-permission',
  contrast_standard_report_print_sort: 'print',
  contrast_standard_report_print_long: '/report/standardreport/print',
  customized_report: '/report/customizedreport',
  summary: 'cdm-metrics/my-dashboard',
  view_account: 'view-account',
  add_account: 'add-account',
  edit_account: 'edit-account',
  account_add_injectors: 'add-injectors',
  view_injectors: 'view-injectors',
  edit_injector: 'edit-injector',
};

export const REPORT_MODE = {
  standard: 'standard',
  custom: 'custom',
  email: 'email',
};

export const REPORT_BASE_URL = '/report/';

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM  YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
